<template>
    <div class="configuracoes-pausas-page">
        <Breadcrumb titulo="RELATORIO DE PAUSAS" :items="item" class="color_produto" />
        <b-container fluid class="w-0">
            <div class="my-2 background-tabela">
                <div class="py-2 px-3">
                    <div class="mb-2 d-flex justify-content-between">
                        <div class="w-50 d-flex">
                            <div class="col-3 p-0 mr-2">
                                <b-form-datepicker id="datepicker-dateformat"
                                                   style="font-size: 14px;"
                                                   :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                   locale="br"
                                                   placeholder="Data de Inicio"
                                                   class="w-0 px-1 rounded-0 border-0"
                                                   v-model="dataInicio"
                                                   @context="onContext" />
                            </div>

                            <div class="col-3 p-0 mr-2">
                                <b-form-datepicker id="datepicker-dateformat2"
                                                   style="font-size: 14px;"
                                                   :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                   locale="br"
                                                   placeholder="Data de Fim"
                                                   class="w-0 px-1 rounded-0 border-0"
                                                   v-model="dataFim" />
                            </div>

                            <div class="col-3 p-0 mr-2">
                                <b-form-select class="w-0 px-1 rounded-0 border-0" v-model="selecionadoFiltroUsuarioId" style="font-size: 14px;">
                                    <b-form-select-option :value="null">Escolha um Usuario</b-form-select-option>
                                    <b-form-select-option v-for="item in listaUsuarios" :value="item.Id">{{item.Nome}}</b-form-select-option>
                                </b-form-select>
                            </div>

                            <div class="col-3 p-0 mr-2">
                                <b-form-select class="w-0 px-1 rounded-0 border-0" v-model="selecionadoFiltroSetorId" style="font-size: 14px;">
                                    <b-form-select-option :value="null">Escolha um Setor</b-form-select-option>
                                    <b-form-select-option v-for="item in listaSetores" :value="item.Id">{{item.Nome}}</b-form-select-option>
                                </b-form-select>
                            </div>

                            <div class="col-3 p-0 mx-3">
                                <b-button squared class="w-0 px-2 w-75 h-100 border-0 cor-fundo-botao" @click="filtroRelatorioDePausas(1)" style="font-size: 14px;">Filtrar</b-button>
                            </div>
                        </div>
                        <div class="d-flex">
                            <b-button squared class="px-4 border-0 cor-fundo-botao" @click="exportarRelatorio" style="font-size: 14px;">Exportar Dados</b-button>
                        </div>
                    </div>
                    <b-table striped
                             responsive
                             hover
                             order
                             :fields="fields"
                             :items="getRelatorioPausasUsuarios"
                             class="tabela-relatorios-btable app-scroll-custom w-0 mb-0">

                        <template #cell(expansao)="row">
                            <div class="text-center">

                                <botao-icone-dinamico :classeIcone="row.detailsShowing ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"
                                                      size=".9rem"
                                                      style="color: black; font-size: 1rem; cursor: pointer;"
                                                      @click="row.toggleDetails" />
                            </div>
                        </template>

                        <template #cell(TempoTotal)="row">
                            <span>{{new Date(row.item.TempoTotal).toLocaleTimeString()}}</span>
                        </template>

                        <template #row-details="row">
                            <div class="bg-white mx-3 p-2">
                                <b-table striped
                                         head-variant="light"
                                         responsive
                                         hover
                                         order
                                         sticky-header
                                         :fields="fieldsChild"
                                         :items="row.item.ListaPausas"
                                         class="background-tabela bg-white app-scroll-custom mb-0">

                                    <template #cell(DataInicio)="row">
                                        <span>{{new Date(row.item.DataInicio).toLocaleDateString()}} {{new Date(row.item.DataInicio).toLocaleTimeString()}}</span>
                                    </template>

                                    <template #cell(TempoGasto)="row">
                                        <span style="width: 40%; display: inline-block;">
                                            {{Math.floor((((new Date(row.item.DataFim) - new Date(row.item.DataInicio)) / 1000 ) / 60 ) / 60 ).toString().padStart(2, "0").trim()}}:{{Math.floor((((new Date(row.item.DataFim) - new Date(row.item.DataInicio)) / 1000 ) / 60 ) % 60 ).toString().padStart(2, "0").trim()}}:{{Math.floor(((new Date(row.item.DataFim) - new Date(row.item.DataInicio)) / 1000 ) % 60 ).toString().padStart(2, "0")}}
                                        </span>
                                    </template>

                                </b-table>

                            </div>
                        </template>
                    </b-table>
                    <paginacao-seta :selecionado="offsetUsuario"
                                    :offset="Math.floor((getQuantidadeDeDados/10.01)+1)"
                                    :isFooter="true"
                                    :tempoAndQuantidadeTotal="getTempoAndQuantidadeTotal"
                                    @eventoPaginacao="filtroRelatorioDePausas" />
                </div>
            </div>
        </b-container>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
    import BotaoIconeDinamico from '@/components/botoes/BotaoIconeDinamico.vue'
    import PaginacaoSeta from '@/components/tabelas/paginacao/PaginacaoSeta.vue'
    export default {
        name: 'RelatorioUsuariosPausas',

        components: {
            BotaoIconeDinamico,
            PaginacaoSeta,
            Breadcrumb
        },

        data() {
            return {
                item: [{
                    id: '1',
                    classe: 'fas fa-table',
                    texto: 'Consulta',
                    link: '/consultas'
                }, {
                    id: '2',
					classe: 'fas fa-user-clock',
                    texto: 'Relatorio Usuarios Pausas',
                    link: '/relatorio-usuarios-pausas'
                }],
                fields: [
                    { key: 'expansao', label: '' },
                    { key: 'Nome', label: 'Usuario', sortable: true, class: 'align-middle' },
                    { key: 'TotalPausas', label: 'Total de Pausas', sortable: true, class: 'text-center align-middle' },
                    { key: 'TempoTotal', label: 'Tempo Total', sortable: true, class: 'text-center align-middle' },
                ],
                fieldsChild: [
                    { key: 'Nome', label: 'Tipo da pausa' },
                    { key: 'DataInicio', label: 'Tempo de Inicio' },
                    { key: 'TempoGasto', label: 'Tempo Gasto' },
                ],
                selecionadoFiltroUsuarioId: null,
                selecionadoFiltroSetorId: null,
                select: 1,
                dataInicio: '',
                dataFim: '',
                offsetUsuario: 1,
                offsetPausas: 1,
                context: null
            }
        },

        watch: {
            selecionadoFiltroUsuarioId() {
                if (this.selecionadoFiltroUsuarioId != null) {
                    this.selecionadoFiltroSetorId = null
                }
            },
            selecionadoFiltroSetorId() {
                if (this.selecionadoFiltroSetorId != null) {
                    this.selecionadoFiltroUsuarioId = null
                }
            },
        },

        computed: {
            ...mapGetters({
                getRelatorioPausasUsuarios: 'usuariosPausados/getRelatorioPausasUsuarios',
                getTempoAndQuantidadeTotal: 'usuariosPausados/getTempoAndQuantidadeTotal',
                getQuantidadeDeDados: 'usuariosPausados/getQuantidadeDeDados',
                getSelecionadoPaginacaoUsuarios: 'usuariosPausados/getSelecionadoPaginacaoUsuarios',
                listaUsuarios: 'usuarios/listaUsuarios',
                listaSetores: 'setores/listaSetores'
            })
        },

        created() {
            this.buscaUsuariosStore();
            this.buscaSetoresStore();
        },

        methods: {
            ...mapActions({
                filtroOuPadraoRelatorioDePausasStore: 'usuariosPausados/filtroOuPadraoRelatorioDePausas',
                exportarRelatorioStore: 'usuariosPausados/exportarRelatorio',
                buscaUsuariosStore: 'usuarios/buscaUsuarios',
                buscaSetoresStore: 'setores/buscaSetores'
            }),
            onContext(ctx) {
                if (!this.dataInicio && !this.dataFim) {
                    this.dataInicio = ctx.activeYMD
                    this.dataFim = ctx.activeYMD
                    this.filtroRelatorioDePausas()
                }
            },
            async filtroRelatorioDePausas(offsetUsuario = 1) {
                var dadosFiltro = {
                    UsuarioId: this.selecionadoFiltroUsuarioId,
                    SetorId: this.selecionadoFiltroSetorId,
                    DataInicio: this.dataInicio,
                    DataFim: this.dataFim,
                    OffsetUsuario: (offsetUsuario - 1),
                };
                this.offsetUsuario = offsetUsuario;
                this.filtroOuPadraoRelatorioDePausasStore(dadosFiltro);
            },
            async exportarRelatorio() {
                var dadosFiltroParaExportacao = {
                    DataInicio: this.dataInicio,
                    DataFim: this.dataFim,
                    TipoRelatorio: 0,
                    Nome: 'Pausas'
                };
                this.exportarRelatorioStore(dadosFiltroParaExportacao)
            },
        }
    }
</script>

<style scoped>
    .configuracoes-pausas-page {
        padding-bottom: 120px;
    }

    .color_produto {
        background-color: #3925d3;
        color: #FFF !important;
    }

    .cor-fundo-botao {
        background-color: var(--cor-primaria-cliente);
    }

    .background-tabela {
        font-size: 12px;
        background-color: var(--cinza-3);
    }

    .next_before {
        padding: 6px;
        cursor: pointer;
        font-weight: bold;
        font-size: 18px;
        vertical-align: middle;
    }

    .tabela-relatorios-btable {
        background-color: #fff;
        height: calc(100vh - 230px);
        margin-bottom: 0 !important;
        font-size: 12px;
    }
</style>